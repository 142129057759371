import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'vue-select/dist/vue-select.css';
import vWow from 'v-wow'
import vSelect from 'vue-select'
import VModal from 'vue-js-modal'
import axiosSetup from '@/helpers/api.js'
import VueCompositionAPI from '@vue/composition-api'
import firebase from 'firebase/app'
import 'firebase/storage'; 
import Notifications from 'vue-notification'

const firebaseConfig = {
	apiKey: "AIzaSyCkBEIq1tRiidP-_P3S1xxTv5WPXbKL-90",
	authDomain: "carrental-594d2.firebaseapp.com",
	projectId: "carrental-594d2",
	storageBucket: "carrental-594d2.appspot.com",
	messagingSenderId: "1010845623052",
	appId: "1:1010845623052:web:9a815857f8eb85b35bdf29",
	measurementId: "G-JNS0843LN8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

axiosSetup();

Vue.config.productionTip = false

Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(vWow);
Vue.use(VModal, { componentName: 'v-modal', dynamicDefault: { scrollable: true, adaptive: true }});
Vue.component('v-select', vSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
