import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [new VuexPersistence().plugin],
  state: {
    config: null,
    config_home: null,
    logged: false,
    token: null,
    userData: null,
    rememberEmail: null,
    reservationData: null,
    orderDetail: null,
    orderToBePaid: null,
    showSideMenu: false,
  },
  getters: {
    config: state => {
      return state.config
    },
    logged: state => {
      return state.logged
    },
    token: state => {
      return state.token
    },
    userData: state => {
      return state.userData
    },
    rememberMeData: state => {
      return state.rememberMeData
    },
    reservationData: state => {
      return state.reservationData
    },
    orderDetail: state => {
      return state.orderDetail
    },
    orderToBePaid: state => {
      return state.orderToBePaid
    },
    showSideMenu: state => {
      return state.showSideMenu
    },

  },
  mutations: {
    setConfig(state, payload) {
      state.config = payload
      state.config_home = payload.home_config
    },
    setLogged(state, payload) {
      state.logged = payload
    },
    setToken(state, payload) {
      state.token = payload
    },
    setUserData(state, payload) {
      state.userData = payload
    },
    setRememberEmail(state, payload) {
      state.rememberEmail = payload
    },
    setReservationData(state, payload) {
      state.reservationData = payload
    },
    setOrderData(state, payload) {
      state.orderDetail = payload
    },
    setOrderToBePaid(state, payload) {
      state.orderToBePaid = payload
    },
    toggleSideMenu(state) {
			state.showSideMenu = !state.showSideMenu;
		}
  },
  actions: {

  },
  modules: {
  }
})
