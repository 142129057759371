/* eslint-disable no-alert */
import axios from 'axios';
import util from './util'
import store from '@/store/index.js'

export default function setup() {

  axios.interceptors.request.use(async (axiosConfig) => {
    const httpConfig = { ...axiosConfig };
    const token = await util.getToken();
    if (token && util.isTokenExpired(token)) {
      store.commit('setLogged', false);
      store.commit('setReservationData', null);
      // console.log(refresh);
      // return util.refreshToken().then(async (data) => {
        // console.log(data);
        // const { token: newToken } = data;
        // store.commit('setLogged', true);
        // store.commit('setToken', newToken);
        // httpConfig.headers.Authorization = `Bearer ${newToken}`;
        // return Promise.resolve(httpConfig);
      // });
    }
    if (token) {
      httpConfig.headers.Authorization = `Bearer ${token}`;
    }
    return httpConfig;
  });

  // axios.interceptors.response.use(
  //   function (response) {
  //     // Any status code that lie within the range of 2xx cause this function to trigger
  //     // Do something with response data
  //     return response;
  //   },
  //   function (err) {
  //     let errorMessage = [];
  //     if (err.response) {
  //       console.log('error response', err.response);

  //       if (err.response.status !== 422) {
  //         errorMessage.push(err.response.data.message || err.response.statusText);
  //       } else {
  //         const { errors } = err.response.data;
  //         console.log(errors);
  //         errorMessage = Object.keys(errors).map((key) => errors[key][0]);
  //       }
  //       if (err.response.status !== 401) {
  //         errorMessage.forEach((message) => console.log(message));
  //       }
  //     } else if (err.request) {
  //       console.log('error request', err.request);
  //     } else {
  //       console.log('Error', err.message);
  //     }
  //     return Promise.reject(err);
  //   },
  // );
}