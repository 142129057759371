import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/vehicles',
    name: 'Vehicle',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehicle.vue')
  },
  {
    path: '/vehicles/?date=:pickdate/?hour:pickhour/?categoryid:categoryid/?brandid:brandid',
    name: 'VehicleFilter',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehicle.vue')
  },
  {
    path: '/vehicles/:pickdate/:pickhour/:categoryid',
    name: 'VehicleFilterCategory',
    component: () => import(/* webpackChunkName: "about" */ '../views/Vehicle.vue')
  },
  {
    path: '/hotdeals',
    name: 'HotDeals',
    component: () => import(/* webpackChunkName: "about" */ '../views/HotDeals.vue')
  },
  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Reservation.vue')
  },
  {
    path: '/booking/:vehicleid',
    name: 'Booking',
    component: () => import(/* webpackChunkName: "about" */ '../views/Booking.vue')
  },
  {
    path: '/success-order',
    name: 'SuccessOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/SuccessOrder.vue')
  },
  {
    path: '/faqs',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/my-profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/order-history',
    name: 'OrderHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderHistory.vue')
  },
  {
    path: '/car-history',
    name: 'CarHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarHistory.vue')
  },
  {
    path: '/test-map',
    name: 'TestMap',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestMap2.vue')
  },
  {
    path: '/test-stripe',
    name: 'TestStripe',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestStripe.vue')
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutUs.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyPolicy.vue')
  },
  {
    path: '/delivery-information',
    name: 'DeliveryInformation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DeliveryInformation.vue')
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TermsConditions.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})


export default router
