import jwtDecode from 'jwt-decode';
import { apiUrl } from '@/constants/config.js'
import axios from 'axios';
import store from '@/store/index.js'

const getTokenExpirationDate = (token) => {
  const decoded = jwtDecode(token);
  if (!Object.prototype.hasOwnProperty.call(decoded, 'exp')) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);
  return date;
};

const util = {
  async getToken() {
    return store.state.token;
  },
  isTokenExpired: (token) => {
    // No Expired Token so set else == true
    // if (!token) {
    //   return true;
    // } else {
    //   return false;
    // }
    const date = getTokenExpirationDate(token);
    if (!date) {
      return true;
    }
    return !(date.valueOf() > new Date().valueOf());
  },

  refreshToken: async () => {
    const token = store.state.token;
    const config = { headers: { Authorization: `Bearer ${token}` } };
    const response = await axios.get(
      `${apiUrl}v1/auth/refresh-token`,
      config,
    );
    console.log(response.data);
    return response.data;
  },
}
export default util;