<template>
  <div class="h-100">
    <div id="undefined-sticky-wrapper" class="sticky-wrapper" style="height:42px;">
    <!-- HEADER -->
    <header class="header fixed" id="mainheader">
      <div class="header-wrapper">
        <div class="container">
          <!-- Logo -->
          <div class="logo">
            <router-link :to="{ path: '/' }">
              <a>
                <img
                  :src="this.$store.state.config.config.logo"
                  alt="Hera Trading"
                />
              </a>
            </router-link>
          </div>
          <!-- /Logo -->

          <!-- Mobile menu toggle button -->
          <a
            href="#"
            @click.prevent="openSideMenu"
            class="
              btn-toggle-header
              menu-toggle
              btn
              ripple-effect
              btn-theme-transparent
              d-md-block d-lg-none
              text-warning
            "
            ><i class="fa fa-bars" style="color: #f1be02 !important"></i
          ></a>
          <!-- /Mobile menu toggle button -->

          <!-- Navigation -->
          <nav
            :class="
              this.$store.state.showSideMenu
                ? 'navigation clearfix opened'
                : 'navigation clearfix closed'
            "
          >
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <!-- navigation menu -->
                <a
                  href="#"
                  @click.prevent="openSideMenu"
                  class="menu-toggle-close btn d-md-block d-lg-none"
                  ><i class="fa fa-times"></i
                ></a>
                <ul class="nav sf-menu">
                  <li class="active">
                    <router-link :to="{ path: '/' }"><a>Home</a></router-link>
                  </li>
                  <!-- <li class="active"><a href="index.html">Home</a>
                                    <ul>
                                        <li><a href="index.html">Home 1</a></li>
                                        <li><a href="index-2.html">Home 2</a></li>
                                        <li><a href="index-3.html">Home 3</a></li>
                                        <li><a href="index-4.html">Home 4</a></li>
                                        <li><a href="index-5.html">Home 5</a></li>
                                        <li><a href="index-6.html">Home 6</a></li>
                                    </ul>
                                </li> -->
                  <li>
                    <router-link :to="{ path: '/hotdeals' }"
                      ><a>Hot Deals</a></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ path: '/vehicles' }"
                      ><a>Vehicle</a></router-link
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0)">Our Terms</a>
                    <ul>
                      <li>
                        <router-link :to="{ path: '/privacy-policy' }">
                          <a>Privacy Policy</a>
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ path: '/terms-conditions' }">
                          <a>Terms & Conditions</a>
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ path: '/delivery-information' }">
                          <a>Delivery Information</a>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <router-link :to="{ path: '/faqs' }"
                      ><a>FAQS</a></router-link
                    >
                  </li>
                  <li>
                    <router-link :to="{ path: '/contact' }"
                      ><a>CONTACT</a></router-link
                    >
                  </li>
                  <!-- <li>
                                    <ul class="social-icons">
                                        <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                                        <li><a href="#" class="pinterest"><i class="fa fa-pinterest"></i></a></li>
                                    </ul>
                                </li> -->
                  <li v-if="!this.$store.state.logged">
                    <router-link :to="{ path: '/login' }">
                      <a>Login</a>
                    </router-link>
                  </li>
                  <li v-if="this.$store.state.logged">
                    <a href="javascript:void(0)">Account</a>
                    <ul>
                      <li>
                        <a href="javascript:void(0)">
                          <i class="far fa-3x fa-user-circle"></i>
                        </a>
                      </li>

                      <li>
                        <router-link :to="{ path: '/my-profile' }">
                          <a>My Profile</a>
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ path: '/order-history' }">
                          <a>My Order History</a>
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ path: '/car-history' }">
                          <a>My Car History</a>
                        </router-link>
                      </li>
                      <li>
                        <a style="cursor: pointer" @click="logout">Logout</a>
                      </li>
                    </ul>
                  </li>
                  <div></div>
                </ul>
                <!-- /navigation menu -->
              </div>
            </div>
            <!-- Add Scroll Bar -->
            <div class="swiper-scrollbar"></div>
          </nav>
          <!-- /Navigation -->
        </div>
      </div>
    </header>
    <!-- /HEADER -->

    <div>
      <router-view />
    </div>

    <!-- FOOTER -->
    <footer class="footer my-5">
      <div class="footer-meta">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <p class="btn-row text-center">
                <a
                  :href="this.$store.state.config.config.facebook"
                  target="_blank"
                  class="
                    btn btn-theme btn-socmed
                    ripple-effect
                    btn-icon-left
                    facebook
                    wow
                    fadeInDown
                  "
                  data-wow-offset="20"
                  data-wow-delay="100ms"
                  ><i class="mr-2 fab fa-facebook-f"></i>FACEBOOK</a
                >
              </p>
              <div class="copyright">
                {{ this.$store.state.config.config.footer_text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- /FOOTER -->
    <div
      v-show="scY > 300"
      @click="toTop"
      id="to-top"
      class="to-top"
      style="bottom: 90px"
    >
      <i class="fa fa-angle-up"></i>
    </div>
    <notifications position="bottom right" />
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
body {
  direction: ltr !important;
}

.btn-toggle-header {
  color: #ff0000 !important;
  font-size: 1.4rem !important;
  border: 1px solid #e5e5e5 !important;
  padding: 0.9rem !important;
}

.btn-socmed {
  padding: 13px 20px !important;
  width: 150px;
  color: #fff !important;
}

.span-dropdown-menu {
  padding: 3px 15px;
}

.sf-menu ul {
  text-align: left;
}

.sf-menu ul li > a {
  padding: 3px 15px;
}

@media only screen and (max-width: 600px) {
  .sf-menu ul {
    line-height: 14px;
    padding: 0 10px;
  }

  .sf-menu ul li > a {
    color: #fff !important;
  }
}

.text-gray-700 {
  color: #99a4ad !important;
}
/* .b-dropdown > button[type="button"] {
    border-radius: 50% !important;
    padding: 0.75rem 1rem !important;
} */
</style>

<script>
import "@/assets/plugins/bootstrap/css/bootstrap.min.css";
import "@/assets/plugins/bootstrap-select/css/bootstrap-select.min.css";
import "@/assets/plugins/fontawesome/css/font-awesome.min.css";
import "@/assets/plugins/prettyphoto/css/prettyPhoto.css";
import "@/assets/plugins/owl-carousel2/assets/owl.carousel.min.css";
import "@/assets/plugins/owl-carousel2/assets/owl.theme.default.min.css";
import "@/assets/plugins/animate/animate.min.css";
import "@/assets/plugins/swiper/css/swiper.min.css";

import "@/assets/plugins/jquery-ui/jquery-ui.min.css";
import "@/assets/plugins/countdown/jquery.countdown.css";
import "@/assets/plugins/datetimepicker/css/bootstrap-datetimepicker.min.css";
import "@/assets/css/theme.css";
import "@/assets/css/styles.css";
import http from "axios";
import { apiUrl } from "@/constants/config.js";

export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    // if (!this.$store.state.config) {
    http
      .get(apiUrl + "v1")
      .then((data) => {
        this.$store.commit("setConfig", data.data);
      })
      .catch((err) => console.log(err));
    // } else {
    // console.log(this.$store.state.config);
    // }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);

        var element = document.getElementById("undefined-sticky-wrapper");
        var mainheader = document.getElementById("mainheader")
      if(this.scY > 300){
        element.classList.add("is-sticky");
        mainheader.classList.add("fix-head");
      } else {
        element.classList.remove("is-sticky");
        mainheader.classList.remove("fix-head");
      }


    },

    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    makeSticky(){
      console.log(window.scrollY);
    },

    logout() {
      this.$store.commit("setLogged", false);
      this.$store.commit("setToken", null);
      this.$store.commit("setUserData", null);
      this.$store.commit("setReservationData", null);
      this.$router.push("/login");
    },
    openSideMenu() {
      this.$store.commit("toggleSideMenu");
    },
  },
};
</script>
<style>
.btn-theme-transparent {
  float: right;
}
</style>
